.device-description {
	background-color: var(--color-theme-whitesmoke);
	padding: var(--section-padding);

	&__title {
		font-size: var(--font-size-theme-7xl);
		font-weight: var(--font-weight-theme-medium);
		max-width: 85%;
		@include mq($until: desktop) {
			line-height: 1.4;
			font-size: var(--font-size-theme-5xl);
			margin-bottom: 10px;
		}
		@include mq($until: mobile) {
			font-size: var(--font-size-theme-xl);
		}
	}
	&__description {
		font-size: var(--font-size-theme-medium);
		max-width: 90%;
		@include mq($until: desktop) {
			font-size: var(--font-size-theme-upper);
		}
	}
}
