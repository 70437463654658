.we-use {
	padding: var(--section-padding);
	&__content-section {
		padding: 3rem 1.5rem 0 1.5rem;
	}
	&__content {
		display: flex;
		overflow: hidden;
		padding-bottom: 1rem;
		@include mq($from: fullhd) {
			align-items: center;
			justify-content: center;
		}
	}
	&__section {
		display: flex;
		width: auto;
		animation: slide-partners 10s linear infinite;
		will-change: transform;
		@keyframes slide-partners {
			from {
				transform: translateX(0%);
			}
			to {
				transform: translateX(-100%);
			}
		}
		@include mq($from: fullhd) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 50px;
			animation: unset;
			&:first-child {
				display: none;
			}
			&:last-child {
				display: none;
			}
		}
	}
	&__item {
		display: flex;
		height: 100px;
		width: 200px;
		margin-right: 20px;
		@include mq($from: fullhd) {
			height: 100px;
			width: 250px;
		}
		&-logo {
			flex: 1;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			&_arm {
				background-image: url(../../../icons/partners/arm.svg);
				background-size: 60%;
			}
			&_basler {
				background-image: url(../../../icons/partners/basler.svg);
			}
			&_dalsa {
				background-image: url(../../../icons/partners/dalsa.svg);
			}
			&_hikvision {
				background-image: url(../../../icons/partners/hikvision.svg);
			}
			&_intel {
				background-image: url(../../../icons/partners/intel.svg);
				background-size: 45%;
			}
			&_kubernetes {
				background-image: url(../../../icons/partners/kubernetes.svg);
			}
			&_nginx {
				background-image: url(../../../icons/partners/nginx.svg);
				background-size: 80%;
			}
			&_postgres {
				background-image: url(../../../icons/partners/postgres.svg);
				background-size: 80%;
			}
			&_opensuse {
				background-image: url(../../../icons/partners/opensuse.svg);
				background-size: 45%;
			}
			&_ubuntu {
				background-image: url(../../../icons/partners/ubuntu.svg);
			}
		}
	}
}
