.device-camera {
	padding: var(--section-padding);
	background-color: var(--color-theme-whitesmoke);
	&__header {
		max-width: 750px;
		margin-bottom: 50px;
		@include mq($until: desktop) {
			margin-bottom: 30px;
		}
		&-title {
			font-size: var(--font-size-theme-5xl);
			font-weight: var(--font-weight-theme-bold);
			line-height: 1.3;
			color: var(--color-theme-black);
			@include mq($until: mobile) {
				font-size: var(--font-size-theme-xl);
			}
		}
	}
	&__description {
		font-size: var(--font-size-theme-medium);
		// color: var(--color-theme-black);
		max-width: 80%;
		margin-bottom: 50px;
		@include mq($until: mobile) {
			font-size: var(--font-size-theme-upper);
			max-width: 95%;
		}
		@include mq($until: small-mobile) {
			max-width: initial;
		}
	}

	&__features {
		&_general {
			display: grid;
			grid-auto-flow: column;
			grid-gap: 30px;
			align-items: end;
			@include mq($until: widescreen) {
				grid-auto-flow: row;
			}
		}
	}

	&__feature {
		$feature: &;
		&_general {
			display: flex;
			flex-direction: column;
			max-width: 400px;
			#{$feature}-group {
				margin: 5px 0;
			}
			#{$feature}-icon {
				width: 40px;
				height: 40px;
				margin-right: 10px;
			}
			#{$feature}-title {
				font-size: var(--font-size-theme-8xl);
				font-weight: var(--font-weight-theme-medium);
			}
			#{$feature}-text {
				font-size: var(--font-size-theme-medium);
			}
		}
		&-group {
			display: flex;
			align-items: center;
		}
		&-icon {
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			&_fps {
				background-image: url(../../../icons/fps.svg);
			}
		}
		&-text {
			line-height: 1.3;
			&_bold {
				font-weight: var(--font-weight-theme-medium);
			}
		}
		&-title {
		}
	}
}
