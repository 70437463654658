.aurora-header {
	display: flex;
	height: 200vh;
	&__background {
		flex: 1;
		display: flex;
		justify-content: center;
	}
	&__title {
		position: sticky;
		align-self: start;
		text-align: center;
		font-weight: var(--font-weight-theme-regular);
		top: 50%;
		font-size: var(--font-size-theme-slogan);
		color: white;
		@include mq($until: small-mobile) {
			font-size: var(--font-size-theme-2xl);
		}
	}
}
