.key-features {
	background-color: var(--color-theme-blue);
	padding: var(--section-padding);
	&__container {
		display: flex;
		justify-content: center;
	}
	&__content {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 80px 50px;
		@include mq($until: desktop) {
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 50px;
		}
		@include mq($until: small-mobile) {
			grid-gap: 30px;
		}
	}
	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		&-icon {
			width: 70px;
			height: 70px;
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: contain;
			background-color: var(--color-theme-white);
			margin-bottom: 15px;
			&_monitor {
				mask-image: url(../../../icons/monitor.svg);
			}

			&_folder {
				mask-image: url(../../../icons/folder.svg);
			}
			&_hash {
				mask-image: url(../../../icons/hash.svg);
			}
			&_api {
				mask-image: url(../../../icons/api.svg);
			}
			&_analysis {
				mask-image: url(../../../icons/analysis.svg);
			}
			&_website {
				mask-image: url(../../../icons/website.svg);
			}
		}
		&-description {
			color: white;
			font-size: var(--font-size-theme-upper);
			text-align: center;
			font-weight: var(--font-weight-theme-regular);
		}
	}
}
