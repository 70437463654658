body {
	font-family: "Roboto", sans-serif;
}

:root {
	--font-size-theme-mini: 14px;
	--font-size-theme-base: 16px;
	--font-size-theme-upper: 18px;
	--font-size-theme-medium: 20px;
	--font-size-theme-large: 22px;
	--font-size-theme-xl: 24px;
	--font-size-theme-2xl: 26px;
	--font-size-theme-3xl: 28px;
	--font-size-theme-4xl: 30px;
	--font-size-theme-5xl: 32px;
	--font-size-theme-6xl: 34px;
	--font-size-theme-7xl: 36px;
	--font-size-theme-8xl: 38px;
	--font-size-theme-slogan: 48px;
	--font-size-theme-big-slogan: 62px;
	--font-weight-theme-regular: 500;
	--font-weight-theme-medium: 600;
	--font-weight-theme-bold: 700;
	--font-weight-theme-upper-bold: 800;
}
