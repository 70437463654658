.company-services {
	background-color: var(--color-theme-gray);
	&__title {
		color: var(--color-theme-black);
		font-weight: var(--font-weight-theme-medium);
		font-size: var(--font-size-theme-5xl);
		margin-bottom: 50px;
	}
	&__products {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		grid-gap: 20px 30px;
		@include mq($until: small-mobile) {
			grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
			max-width: unset;
		}
		@include mq($until: tablet) {
			grid-gap: 20px 50px;
		}
	}
}
