.nav-bar {
	height: 60px;
	background-color: var(--color-theme-whitesmoke);
	$navbar: &;
	@include mq($until: desktop) {
		padding: 0 1.5rem;
	}
	&__container {
		height: 100%;
		> div {
			height: 100%;
		}
	}
	&__desktop {
		@include mq($from: 0, $until: small-mobile) {
			display: none !important;
		}
	}
	&__mobile {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include mq($from: small-mobile) {
			display: none;
		}
		&-content {
			background-color: var(--color-theme-whitesmoke);
			position: absolute;
			right: -20px;
			left: -1.5rem;
			width: 100vw;
			top: 60px;
			height: calc(100vh - 60px);
			z-index: 10;
			padding: 1.5rem;
			transform: translateX(-100vw);
			transition: all 0.2s ease-in;
			#{$navbar}__links {
				flex-direction: column;
			}
			#{$navbar}__link {
				margin: 0 0 15px 0;
				&:last-child {
					margin: 0;
				}
			}
			&_opend {
				transform: translateX(0);
			}
		}
	}

	&__left {
	}
	&__right {
		width: 200px;
		> a {
			background-position: center right;
		}
	}
	&__logo {
		height: 35px;
        width: 100%;
        display: block;
		//
		background-image: url(../../../icons/logo.svg);
		background-position: center left;
		background-repeat: no-repeat;
		background-size: contain;
	}
	&__links {
		display: flex;
		justify-content: center;
	}
	&__link {
		margin: 10px;
		color: var(--color-theme-black);
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	&__contact {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		@include mq($until: mobile) {
			display: none;
		}
	}
	&__phone {
		color: var(--color-theme-black);
		font-weight: var(--font-weight-theme-medium);
		font-size: var(--font-size-theme-upper);
		margin-right: 20px;

		@include mq($from: widescreen, $until: fullhd) {
			margin-right: 10px;
		}
		@include mq($until: desktop) {
			display: none;
		}
	}
	//вынести в отдельный компонент
	&__feedback {
		display: inline-flex;
		padding: 5px 15px;
		font-size: var(--font-size-theme-base);
		font-weight: var(--font-weight-theme-medium);
		background-color: var(--color-theme-blue);
		color: var(--color-theme-white);
		border-radius: 100px;
		cursor: pointer;
		@include mq($from: desktop, $until: widescreen) {
			display: none;
		}
	}

	// &__menu {
	// 	width: 30px;
	// 	height: 30px;
	// 	display: flex;
	// 	justify-content: space-around;
	// 	flex-direction: column;
	// 	span {
	// 		height: 2px;
	// 		background-color: var(--color-theme-black);
	// 		&:nth-child(1) {
	// 		}
	// 		&:nth-child(2) {
	// 		}
	// 		&:nth-child(3) {
	// 		}
	// 	}
	// }
	&__menu-icon {
		display: flex;
		align-items: center;
		position: relative;
		width: 35px;
		height: 35px;
		cursor: pointer;
		transition: all 0.3s;
		> div {
			position: absolute;
			width: 100%;
			height: 3px;
			background-color: #e74c3c;
			border-radius: 3px;
			&:before,
			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: #e74c3c;
				border-radius: 3px;
				transition: all 0.3s;
			}
			&:before {
				transform: translateY(-13px);
			}
			&:after {
				transform: translateY(13px);
			}
		}
		&_opend {
			transform: rotate(45deg);
			div {
				&:before {
					transform: rotate(90deg);
				}
				&:after {
					transform: rotate(90deg);
				}
			}
		}
	}
}
