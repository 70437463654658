.average-speed {
	padding: var(--section-padding);
	background-color: var(--color-theme-whitesmoke);

	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 500px;
		&_hidden {
			height: 400px;
		}
	}
	&__content {
		position: absolute;
		display: flex;
		height: 500px;
		width: 100vw;
		background-color: wheat;
		&_hidden {
			position: static;
			width: 100%;
			height: 400px;
		}
	}
	&__background {
		position: relative;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		overflow: hidden;
		$background: &;
		&-shadow {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}
		&-car {
			position: absolute;
			width: 300px;
			height: 200px;
			background-image: url(../../../images/car.png);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
		&-line {
			display: flex;
			align-items: center;
			height: 30px;
			width: 100%;
		}
		&-elements {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: 180px;
			padding: 0 100px;
			&_hidden {
				height: 150px;
				#{$background}-pulse {
					height: 40%;
				}
			}
		}
		&-pulse {
			width: 10%;
			height: 50%;
			overflow: visible;
		}
	}
	&__line {
		width: 100%;
		height: 6px;
		line {
			stroke: var(--color-theme-upper-gray);
			stroke-width: 10px;
			stroke-dasharray: 100;
		}
	}
	&__pulse {
		width: 100%;
		height: 100%;
		overflow: visible;
		&-circle {
			transform-origin: center center;
			fill: black;
		}
	}

	&__features {
		position: absolute;
		left: 50px;
		right: 50px;
		bottom: 50px;
		display: flex;
		justify-content: space-between;

		@include mq($until: tablet) {
			left: 20px;
			right: 20px;
			bottom: 30px;
		}
	}

	&__feature {
		display: flex;
		flex-direction: column;
		max-width: 320px;
		@include mq($until: mobile) {
			align-items: center;
			text-align: center;
			margin-right: 10px;
			&:last-child {
				margin-right: 0;
			}
		}

		&-title {
			font-size: var(--font-size-theme-large);
			font-weight: var(--font-weight-theme-medium);
			@include mq($until: mobile) {
				font-size: var(--font-size-theme-medium);
			}
		}
		&-description {
			@include mq($until: mobile) {
				font-size: var(--font-size-theme-mini);
			}
		}
	}
}
