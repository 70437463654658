.page-footer {
	background-color: var(--color-theme-gray);
	padding: 20px 0;
	@include mq($until: desktop) {
		padding: 20px 1.5rem;
	}
	&__part {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__logo {
		height: 30px;
		width: 100px;
		background-image: url(../../../icons/logo.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		@include mq($until: mobile) {
			display: none;
		}
	}
	&__links {
		@include mq($until: small-mobile) {
			display: none;
		}
	}
	&__link {
		margin: 0 10px;
		color: var(--color-theme-black);
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	&__separator {
		height: 2px;
		background-color: var(--color-theme-upper-gray);
		border-radius: 2px;
		margin: 10px 0;
		@include mq($until: small-mobile) {
			display: none;
		}
	}
	&__info {
		@include mq($until: mobile) {
			max-width: 70%;
		}
		@include mq($until: small-mobile) {
			max-width: 55%;
		}
		&_phone {
			color: var(--color-theme-black);
			font-weight: var(--font-weight-theme-medium);
		}
	}
}
