:root {
	--color-theme-white: #ffffff;
	--color-theme-whitesmoke: #f5f5f5;
	--color-theme-gray: #eeeeee;
	--color-theme-upper-gray: #c8c8c8;
	--color-theme-dark-gray: #525252;
	--color-theme-black: #000000;
	--color-theme-light-black: #2a2a2a;
	--color-theme-blue: #1089ff;
	--color-theme-gray-blue: #3c5ea9;
	--color-theme-dark-blue: #1f325a;
}
