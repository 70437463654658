.feedback-form {
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	&__background {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
	}
	&__close {
		position: absolute;
		width: 30px;
		height: 30px;
		background-color: var(--color-theme-gray);
		right: -10px;
		top: -10px;
		border-radius: 100px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
		background-image: url(../../../icons/close.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 35%;
		cursor: pointer;
		transition: all 0.2s ease-in;
		&:hover {
			transform: scale(1.01);
		}
	}
	&__section {
		display: flex;
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__column {
		margin-right: 50px;
		flex: 1;
		&:last-child {
			margin-right: 0;
		}
	}
	&__group {
		display: flex;
	}

	&__container {
		z-index: 20;
		position: relative;
		width: 55vw;
		padding: 50px 40px;
		border-radius: 5px;
		background-color: var(--color-theme-white);
	}
	&__form {
		display: flex;
		flex-direction: column;
	}
	&__input {
		background-color: var(--color-theme-whitesmoke);
		border-radius: 5px;
		padding: 10px 20px;
		color: var(--color-theme-black);
		&-wrapper {
			position: relative;
			display: flex;
			align-items: center;
			width: max-content;
		}
		&-notification {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: var(--font-size-theme-mini);
			right: 20px;
			width: 22px;
			height: 22px;
			border-radius: 100px;
			background-color: var(--color-theme-gray);
			border: 2px solid var(--color-theme-upper-gray);
		}
		&::placeholder {
			color: var(--color-theme-upper-gray);
		}
		&_text {
			height: 100px;
		}
		&-group {
			margin-bottom: 20px;
			&_text {
				width: 100%;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	&__label {
		margin-bottom: 5px;
		color: var(--color-theme-black);
	}
	&__button {
		color: var(--color-theme-white);
		background-color: var(--color-theme-black);
		font-weight: var(--font-weight-theme-regular);
		font-size: var(--font-size-theme-mini);
		display: inline-flex;
		align-self: center;
		border: none;
		transition: all 0.25s ease-in;
		&-wrapper {
			margin-top: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&-loader {
			@include loader;
			position: absolute;
			width: 20px;
			height: 20px;
			opacity: 1;
			&_disabled {
				visibility: hidden;
				opacity: 0;
			}
		}
		&_hidden {
			color: var(--color-theme-black);
		}
		&_disabled {
			background-color: var(--color-theme-dark-gray);
			cursor: not-allowed;
		}
	}
	&__success {
		display: flex;
		flex-direction: column;
		align-items: center;
		&-icon {
			width: 80px;
			height: 80px;
			background-image: url(../../../icons/success.svg);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			margin-bottom: 20px;
		}
		&-title {
			font-size: var(--font-size-theme-xl);
			margin-bottom: 5px;
		}
		&-description {
			font-size: var(--font-size-theme-upper);
			text-align: center;
			width: 80%;
		}
	}
}
