.company-service {
	width: auto;
	height: 200px;
	overflow: hidden;
	&__icon {
		width: 80px;
		height: 80px;
		margin-bottom: 10px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 80%;
		opacity: 0.8;
		&_hardware {
			background-image: url(../../../icons/hardware.svg);
		}
		&_software {
			background-image: url(../../../icons/software-development.svg);
		}
		&_integraion {
			background-image: url(../../../icons/integration.svg);
		}
		&_support {
			background-image: url(../../../icons/support.svg);
		}
		&_buy {
			background-image: url(../../../icons/buy.svg);
		}
		&_technical-support {
			background-image: url(../../../icons/technical-support.svg);
		}
	}
	&__title {
		color: var(--color-theme-black);
		font-size: var(--font-size-theme-upper);
		line-height: 1.4;
		margin-left: 5px;
	}
}
