.software-slider {
    background-color: var(--color-theme-gray);
    padding: var(--section-padding);
	&__header {
		display: flex;
		justify-content: center;
		margin-bottom: 50px;
		&-text {
			color: var(--color-theme-white);
			font-weight: var(--font-weight-theme-medium);
			font-size: var(--font-size-theme-3xl);
			max-width: 800px;
			text-align: center;
			line-height: 1.3;
		}
	}
	&__content {
	}

	&__slider {
		height: 550px;
		display: flex;
		flex-direction: column;
		background-color: var(--color-theme-white);
		border-radius: 10px;
        padding: 30px 40px 40px 40px;
        box-shadow: 0 0 50px -20px rgba(0, 0, 0, 0.1);
		$slider: &;
		@include mq($until: small-mobile) {
			padding: 10px 20px 20px 20px;
		}
		.slick-slider {
			flex: 1;
			display: flex;
			.slick-list {
				overflow: hidden;
				flex: 1;
				display: flex;
				.slick-track {
					display: flex;
					height: 100%;
					.slick-slide {
						display: flex;
						flex: 1;
						> div {
							display: flex;
							flex: 1;
						}
					}
				}
			}
		}

		&-sections {
			display: flex;
			&-container {
				width: 100%;
				@include mq($until: tablet) {
					overflow-y: scroll;
				}
				#{$slider}-section {
					@include mq($until: mobile) {
						padding: 0 30px;
					}
				}

				@include mq($until: tablet) {
					margin-bottom: 30px;
				}
			}
		}
		&-section {
			flex: 1;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--color-theme-gray);
            color: var(--color-theme-black);
            cursor: pointer;
			&:first-child {
				border-radius: 5px 0 0 5px;
			}
			&:last-child {
				border-radius: 0 5px 5px 0;
			}
			&_active {
				font-weight: var(--font-weight-theme-medium);
                cursor: default;
            }
		}
		&-image {
			width: 50%;
			height: 70%;
			border-radius: 10px;
			background-color: rgb(221, 221, 221);
			margin-right: 30px;
			@include mq($until: tablet) {
				width: 70%;
                height: unset;
                margin-right: 0;
				margin-bottom: 20px;
			}
		}
		&-content {
			display: flex !important;
			align-items: center;
			flex: 1;
			outline: none;
			@include mq($until: tablet) {
				flex-direction: column;
			}

			> div {
				flex: 1;
			}
		}
	}
	&__info {
		&-header {
			color: var(--color-theme-black);
			font-weight: var(--font-weight-theme-medium);
			font-size: var(--font-size-theme-upper);
			margin-bottom: 10px;
			&_mini {
				font-size: var(--font-size-theme-mini);
				line-height: 1;
				margin-bottom: 0;
			}
		}
		&-text {
			color: var(--color-theme-black);
			margin-bottom: 20px;
		}
	}
	&__checkitems {
		display: grid;
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px 0;
	}
	&__checkitem {
		display: flex;
		align-items: center;
		&-box {
			position: relative;
			width: 20px;
			height: 20px;
			background-color: var(--color-theme-gray);
			border-radius: 3px;
			margin-right: 7px;
			&-icon {
				fill: none;
				// width: 50px;
				// height: 50px;
				path {
					stroke-width: 1.3;
				}
				&-container {
					position: absolute;
					width: 20px;
					height: 20px;
					right: -4px;
					top: -3px;
				}
			}
		}

		&-text {
		}
	}
}
