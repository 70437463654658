.company-achievement {
	width: 120px;
	height: 120px;
	position: relative;
	display: flex;
	margin-bottom: 60px;
	margin-right: 150px;
	&__icon {
		width: 100%;
		height: 100%;
		mask-repeat: no-repeat;
		mask-position: center;
		mask-size: contain;
		background-color: var(--color-theme-upper-gray);
		&_hexagon {
			mask-image: url(../../../icons/hexagon-with-shapes-inside.svg);
		}
		&_triangle {
			mask-image: url(../../../icons/triangle.svg);
		}
		&_oval {
			mask-image: url(../../../icons/oval.svg);
			& + div {
				transform: translate(35%, 25%);
			}
		}
		&_circle {
			mask-image: url(../../../icons/circle.svg);
		}
	}
	&__info {
		position: absolute;
		transform: translate(45%, 25%);
	}
	&__number {
		color: var(--color-theme-blue);
		font-weight: var(--font-weight-theme-medium);
		font-size: 84px;
		line-height: 1;
	}
	&__title {
		color: var(--color-theme-black);
		font-size: var(--font-size-theme-xl);
		line-height: 1.2;
		margin-left: 5px;
		@include mq($until: mobile) {
			font-size: var(--font-size-theme-large);
		}
	}
}
