.about-us {
	display: flex;
    align-items: center;
    justify-content: center;
	height: calc(80vh - 60px);
	background-color: var(--color-theme-dark-blue);
	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		@include mq($until: desktop) {
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	}
	&__title {
		color: var(--color-theme-white);
		font-weight: var(--font-weight-theme-medium);
		font-size: var(--font-size-theme-slogan);
		flex: 1;
		font-weight: 600;
		@include mq($until: mobile) {
			font-size: var(--font-size-theme-5xl);
		}
	}
	&__description {
		color: var(--color-theme-white);
		font-size: var(--font-size-theme-xl);
		flex: 2;
		@include mq($until: mobile) {
			font-size: var(--font-size-theme-medium);
		}
	}
}
