.aurora-navigation {
	position: relative;
	background-color: var(--color-theme-black);
	padding: var(--section-padding);

	&__background {
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		mask-position: center;
		mask-image: url(../../../extra/aurora-sections.svg);
		background: linear-gradient(to bottom, #666666 0%, #242424 50%, #242424 50%, #666666 100%);
	}
	&__content {
		display: flex;
		align-items: center;
		padding: 50px 0;
		@include mq($until: widescreen) {
			flex-direction: column;
			align-items: initial;
		}
		&-left {
			flex: 1;
			margin-right: 100px;
			@include mq($until: widescreen) {
				margin-right: 0;
				margin-bottom: 50px;
			}
		}
		&-right {
			flex: 1;
		}
	}
	&__title {
		font-size: var(--font-size-theme-6xl);
		font-weight: var(--font-weight-theme-upper-bold);
		color: var(--color-theme-white);
		margin-bottom: 20px;
	}
	&__description {
		font-size: var(--font-size-theme-upper);
		font-weight: var(--font-weight-theme-regular);
		color: var(--color-theme-white);
	}

	&__cards {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		@include mq($until: widescreen) {
			align-items: initial;
		}
		&-row {
			display: flex;
			@include mq($until: small-mobile) {
				flex-direction: column;
			}
			&_down {
				align-items: flex-end;
				margin-bottom: 20px;
			}
			&_up {
				align-items: flex-start;
			}
		}
	}

	&__card {
		background-color: var(--color-theme-light-black);
		width: 370px;
		padding: 30px 25px;
		border-radius: 5px;
		@include mq($until: widescreen) {
			flex: 1;
			width: auto;
		}
		&:first-child {
			margin-right: 20px;
			@include mq($until: small-mobile) {
				margin-right: 0;
				margin-bottom: 20px;
			}
		}
		&-icon {
			width: 40px;
			height: 40px;
			mask-repeat: no-repeat;
			mask-position: center;
			background-color: var(--color-theme-whitesmoke);
			margin-bottom: 10px;
			&_test {
				mask-image: url(../../../icons/photo-camera.svg);
			}
		}
		&-content {
			display: flex;
			flex-direction: column;
		}
		&-title {
			font-size: var(--font-size-theme-upper);
			font-weight: var(--font-weight-theme-regular);
			color: var(--color-theme-white);
			margin-bottom: 5px;
		}
		&-description {
			color: var(--color-theme-white);
			line-height: 1.4;
		}
	}
}
