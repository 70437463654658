.item-document {
	margin-bottom: 15px;
	&:last-child {
		margin-bottom: 0;
	}
	$root: &;
	&_primary {
		margin-bottom: 0;
		#{$root}__header {
			font-size: var(--font-size-theme-3xl);
			line-height: 1.2;
			margin-bottom: 15px;
		}
		#{$root}__subtitle {
			font-size: var(--font-size-theme-upper);
		}
	}
	&__header {
		color: var(--color-theme-black);
		font-weight: var(--font-weight-theme-medium);
		font-size: var(--font-size-theme-medium);
		display: inline-block;
		line-height: 1.3;
		margin-bottom: 5px;
	}
	&__subtitle {
		color: var(--color-theme-black);
		margin-bottom: 7px;
	}
    &__tags {
        display: flex;
    }
	&__tag {
		display: flex;
		align-items: center;
		color: var(--color-theme-dark-gray);
        margin-right: 22px;
		&:hover,
		&:active {
			color: var(--color-theme-black);
		}
        &:last-child {
            margin-right: initial;
        }
		&-icon {
			height: 17px;
			width: 17px;
			//
			mask-image: url(../../../icons/tag.svg);
			mask-repeat: no-repeat;
			mask-size: contain;
			background-color: var(--color-theme-black);
			margin-right: 7px;
		}
		&-name {
		}
	}
}
