.page-feedback {
	background-color: var(--color-theme-dark-blue);
	// padding: 30px 0px 35px 30px;
	&__title {
		color: var(--color-theme-white);
		font-weight: var(--font-weight-theme-medium);
		font-size: var(--font-size-theme-3xl);
		// margin-bottom: 30px;
	}
	&__description {
		color: var(--color-theme-white);
		font-size: var(--font-size-theme-upper);
	}

	&__inputs {
		display: flex;
		flex-direction: column;
		margin-top: 40px;
		width: fit-content;
		&-group {
			display: flex;
			align-items: center;
            width: fit-content;
            flex-wrap: wrap;
			&:first-child {
				margin-bottom: 20px;
			}
			&_text {
				width: 100%;
			}
		}
	}
	&__input {
		padding: 10px 0;
		font-size: var(--font-size-theme-2xl);
		color: var(--color-theme-white);
		font-weight: var(--font-weight-theme-medium);
		appearance: none;
		border: none;
		border-bottom: 1px solid;
		background-color: transparent;
		outline: none;
        margin-right: 40px;
        margin-bottom: 20px;
		width: 300px;
		&_email {
			width: 300px;
		}
		&_text {
			height: 55px;
            flex: 1;
            @include mq($until: mobile){
                flex: unset;
                margin-bottom: 30px;
            }
		}
		&:last-child {
			margin-right: 0;
		}
		&::placeholder {
			color: var(--color-theme-whitesmoke);
		}
	}
	&__button {
		padding: 7px 25px;
		background-color: var(--color-theme-blue);
		color: var(--color-theme-white);
		font-size: var(--font-size-theme-upper);
		border-radius: 100px;
        margin-left: 50px;
        @include mq($until: mobile){
            margin: 0;
        }
	}
}
