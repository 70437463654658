.download-software {
	background-color: var(--color-theme-black);
	padding: var(--section-padding);
	&__container {
		background-color: var(--color-theme-light-black);
		border-radius: 10px;
		padding: 40px 50px;
		background-image: url(../../../icons/software-background.svg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	&__content {
		display: flex;
		&-left {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1;
		}
		&-right {
			flex: 1;
			@include mq($until: tablet) {
				display: none;
			}
		}
		&-logo {
		}

		&-title {
			color: var(--color-theme-white);
			font-size: var(--font-size-theme-large);
			font-weight: var(--font-weight-theme-regular);
			line-height: 1.3;
			max-width: 80%;
			margin-bottom: 100px;
			@include mq($until: tablet) {
				max-width: 90%;
			}
		}
		&-description {
			color: var(--color-theme-white);
			font-weight: var(--font-weight-theme-regular);
			color: #afafaf;
			line-height: 1.4;
			margin-bottom: 50px;
		}
	}
	&__download {
		&-button {
			padding: 10px 20px;
			color: var(--color-theme-white);
			background-color: var(--color-theme-dark-gray);
			font-weight: var(--font-weight-theme-regular);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			width: 60%;
			margin-bottom: 20px;
			cursor: pointer;
			transition: all 0.15s ease-in-out;
			@include mq($until: small-mobile) {
				width: auto;
			}
			&:hover {
				color: var(--color-theme-white);
				transform: scale(1.01);
			}
		}
		&-hash {
			color: var(--color-theme-white);
			font-size: var(--font-size-theme-mini);
            line-height: 1;
            @include mq($until: small-mobile) {
				font-size: 12px;
			}
		}
	}
	&__steps {
		display: flex;
		flex-direction: column;
	}
	&__step {
		position: relative;
		display: flex;
		flex-shrink: 0;
		margin-bottom: 55px;
		$step: &;
		&:last-child {
			margin-bottom: 0;
			#{$step}-icon {
				&::after {
					display: none;
				}
			}
		}
		&-icon {
			width: 30px;
			height: 30px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--color-theme-white);
			background-color: var(--color-theme-dark-gray);
			border: 2px solid var(--color-theme-upper-gray);
			border-radius: 100px;
			margin-right: 15px;
			&::after {
				content: "";
				position: absolute;
				width: 2px;
				height: 100%;
				align-self: flex-start;
				background-color: var(--color-theme-upper-gray);
				transform: translateY(40px);
				border-radius: 100px;
			}
		}
		&-text {
			color: var(--color-theme-white);
			font-weight: var(--font-weight-theme-regular);
			line-height: 1.3;
		}
	}
}
