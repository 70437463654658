.features-slider {
	background-color: var(--color-theme-blue);
	padding: var(--section-padding);
	&__header {
		display: flex;
		justify-content: center;
		margin-bottom: 50px;
		&-text {
			color: var(--color-theme-white);
			font-weight: var(--font-weight-theme-medium);
			font-size: var(--font-size-theme-3xl);
			max-width: 800px;
			text-align: center;
			line-height: 1.3;
		}
	}
	&__content {
		display: flex;
		justify-content: stretch;
	}
	&__items {
		display: flex;
		flex: 1;
		overflow: hidden;
		.swiper-container {
			flex: 1;
			// display: flex;
			// overflow: hidden;
			.swiper-wrapper {
				flex: 1;
				// display: flex;
				// .swiper-slide {
				// 	display: flex;
				// }
				// .slick-track {
				// 	display: flex;
				// 	height: 100%;
				.swiper-slide {
					width: 250px;
					margin-right: 20px;
					display: flex;
					// 		flex: 1;
					// 		> div {
					// 			display: flex;
					// 		}
				}
				// }
			}
		}
	}
	&__item {
		// width: 250px !important;
		background-color: white;
		border-radius: 10px;
		padding: 30px 20px;
		&-icon {
			width: 40px;
			height: 40px;
			background-image: url(../../../icons/loop.svg);
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			margin-bottom: 15px;
			&_monitoring {
				background-image: url(../../../icons/monitoring.svg);
			}
			&_security {
				background-image: url(../../../icons/security.svg);
			}
			&_quality {
				background-image: url(../../../icons/shutter.svg);
			}
			&_photo-camera {
				background-image: url(../../../icons/photo-camera.svg);
			}
		}
		&-title {
			color: var(--color-theme-black);
			font-weight: var(--font-weight-theme-medium);
			margin-bottom: 10px;
		}
		&-description {
		}
	}
}
