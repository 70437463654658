.time-accuracy {
	padding: var(--section-padding);
	background-color: var(--color-theme-whitesmoke);

	&__time {
		display: flex;
		align-items: flex-end;
		height: 65px;
		transform-origin: left;
		margin-bottom: 10px;
	}
	&__value {
		font-size: 68px;
		font-weight: var(--font-weight-theme-medium);
		line-height: 1;
		margin-right: 20px;
		@include mq($until: mobile) {
			font-size: var(--font-size-theme-slogan);
			margin-right: 7px;
		}
	}
	&__unit {
		font-size: 56px;
		font-weight: var(--font-weight-theme-medium);
		line-height: 1;
		@include mq($until: mobile) {
			font-size: var(--font-size-theme-8xl);
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	&__slogan {
		font-size: var(--font-size-theme-xl);
		font-weight: var(--font-weight-theme-regular);
		max-width: 80%;
		line-height: 1.3;
		@include mq($until: mobile) {
			font-size: var(--font-size-theme-medium);
			max-width: initial;
		}
	}
	&__text {
		margin-top: 20px;
		font-size: var(--font-size-theme-large);
		max-width: 95%;
		@include mq($until: mobile) {
			font-size: var(--font-size-theme-upper);
			max-width: initial;
		}
	}
}
