.company-achievements {
	background-color: var(--color-theme-gray);
	padding: 50px 0;
	&__content {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		@include mq($until: widescreen) {
			grid-template-columns: repeat(2, 1fr);
			justify-items: center;
			grid-gap: 100px 50px;
		}
		@include mq($until: tablet) {
			grid-template-columns: repeat(1, 1fr);
			justify-items: center;
			grid-gap: 50px;
		}
	}
}
