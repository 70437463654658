.company-solution {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--color-theme-white);
	padding: 30px 30px 40px 40px;
	width: auto;
	height: 400px;
	border-radius: 15px;
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
	&__info {
		&-title {
			color: var(--color-theme-black);
			font-size: var(--font-size-theme-upper);
			font-weight: var(--font-weight-theme-medium);
			margin-bottom: 10px;
		}
		&-description {
			color: var(--color-theme-black);
			line-height: 1.4;
		}
	}

	&__more {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__icon {
		width: 120px;
		height: 120px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		&_oko {
			background-image: url(../../../icons/oko-preview.svg);
		}
		&_aurora {
			background-image: url(../../../icons/aurora-preview.svg);
		}
		&_cortes {
		}
	}

	&__button {
		display: flex;
		align-items: center;
		margin-top: auto;
		margin-bottom: 30px;
		color: var(--color-theme-black);
		&_hidden {
			opacity: 0;
		}

		&-text {
			margin-right: 7px;
			font-weight: var(--font-weight-theme-medium);
		}
		&-icon {
			width: 15px;
			height: 15px;
			background-color: var(--color-theme-black);
			mask-image: url(../../../icons/right-arrow.svg);
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: contain;
		}
	}
}
