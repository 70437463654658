.available-violations {
	background-color: var(--color-theme-black);
	padding: var(--section-padding);
	&__section {
		padding-right: 0;
		padding-left: 0;
	}

	&__container {
	}
	&__line {
		display: flex;
		&:first-child {
			margin-bottom: 100px;
		}
	}
	&__slide {
		display: flex;
		width: auto;
		margin-right: 50px;
		@include mq($until: mobile) {
			margin-right: 30px;
		}
	}
	&__card {
		background-color: var(--color-theme-white);
		width: 200px;
		height: 250px;
		padding: 20px 15px;
		border-radius: 5px;
		flex-shrink: 0;
		box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
		@include mq($until: mobile) {
			width: 180px;
			height: 230px;
		}

		&-ident {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			&-type {
				width: 35px;
				height: 35px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: var(--font-weight-theme-regular);
				background-color: #929292;
				color: var(--color-theme-white);
				font-size: var(--font-size-theme-upper);
				border-radius: 100px;
				box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
				z-index: 1;
				transform: scale(0.95);
			}
			&-number {
				color: var(--color-theme-white);
				font-size: var(--font-size-theme-upper);
				font-weight: var(--font-weight-theme-regular);
				background-color: var(--color-theme-dark-gray);
				height: 35px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 15px;
				transform: translateX(-7px);
				line-height: 1;
				border-radius: 100px;
				box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
			}
		}
		&-type {
			font-size: var(--font-size-theme-mini);
			margin: 5px 0;
		}

		&-title {
			font-weight: var(--font-weight-theme-regular);
		}
	}
}
