.view-model {
	padding: var(--section-padding);
	background-color: var(--color-theme-whitesmoke);
	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		// height: 500px;
		&_active {
			height: 600px;
		}
	}

	&__container {
		display: flex;
		width: 100%;
		// height: 500px;
		padding: 50px 60px;
		border-radius: 10px;
		background-color: var(--color-theme-black);
		// background-image: url(../../../icons/aurora-case.svg);
		// background-position: 900% center;
		// background-size: 95%;
		// background-repeat: no-repeat;
		@include mq($until: tablet) {
			flex-direction: column;
			padding: 40px 20px;
		}
		&_active {
			position: absolute;
			width: 90vw;
			height: 600px;
		}
		&-left {
			flex: 1;
			@include mq($until: tablet) {
				margin-bottom: 50px;
			}
		}
		&-right {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&-icon {
			margin-bottom: 10px;
			&-image {
				width: 40px;
				height: 40px;
				background-image: url(../../../icons/3d.svg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
		}
		&-title {
			font-size: var(--font-size-theme-3xl);
			font-weight: var(--font-weight-theme-medium);
			color: var(--color-theme-white);
			line-height: 1.3;
			margin-bottom: 60px;
			@include mq($until: tablet) {
				margin-bottom: 15px;
				font-size: var(--font-size-theme-xl);
			}
			@include mq($until: small-mobile) {
				font-size: var(--font-size-theme-large);
				margin-bottom: 15px;
			}
		}
		&-subtitle {
			font-size: var(--font-size-theme-upper);
			color: var(--color-theme-white);
		}
	}
	&__load-button {
		display: flex;
		align-items: center;
		justify-content: center;
		&-container {
			width: 90px;
			height: 90px;
		}
		&-ring {
			fill: none;
			stroke-width: 2px;
			stroke: var(--color-theme-whitesmoke);
		}
		&-icon {
			position: absolute;
			width: 22px;
			height: 30px;
			path {
				fill: var(--color-theme-white);
			}
		}
	}
	&__aurora-case {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 35%;
		//
		background-image: url(../../../icons/aurora-case.svg);
		background-position: center;
		background-size: 95%;
		background-repeat: no-repeat;
		// background-color: rgba(255, 255, 255, 0.2);
	}
	&__model {
        flex: 1;
	}
}
