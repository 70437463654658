.company-jumbotron {
	height: calc(150px + 3rem);
	background-color: var(--color-theme-blue);
	&__container {
		display: flex;
		justify-content: center;
	}
	&__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 300px;
		width: 100%;
		border-radius: 10px;
		transform: translate(0, -50%);

		background-color: var(--color-theme-black);
		box-shadow: 0 0 19px rgba(0, 0, 0, 0.25);
	}
	&__logo {
		flex: 1;
		height: 100%;
		background-image: url(../../../icons/logo-white.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 70%;
		@include mq($until: tablet) {
			flex: unset;
			width: 250px;
		}
		@include mq($until: mobile) {
			display: none;
		}
	}
	&__description {
		flex: 1;
		padding: 20px;
		color: var(--color-theme-white);
		&-title {
			font-weight: var(--font-weight-theme-medium);
			font-size: var(--font-size-theme-large);
		}
		&-text {
			font-size: var(--font-size-theme-upper);
		}
	}
}
