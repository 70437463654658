.company-location {
	background-color: var(--color-theme-gray-blue);
	padding: var(--section-padding);
	&__content {
		height: 500px;
		position: relative;
		display: flex;
		align-items: center;
	}
	&__map {
		align-self: stretch;
		flex: 1;
		overflow: hidden;
		border-radius: 10px;
	}
	&__info {
		position: absolute;
		left: 50px;
		z-index: 10;
		width: 370px;
		overflow: hidden;
		border-radius: 10px;
		box-shadow: 0 0 20px 15px rgba(0, 0, 0, 0.1);
		@include mq($until: tablet) {
			left: 20px;
			top: 20px;
		}
		@include mq($until: mobile) {
			right: 20px;
			left: 20px;
			bottom: 20px;
			top: unset;
			width: auto;
		}
		&-section {
			padding: 20px 30px;
			&_contacts {
				background-color: var(--color-theme-blue);
			}
			&_location {
				background-color: var(--color-theme-white);
			}
		}
		&-item {
			display: flex;
			align-items: center;
		}
		&-content {
			color: var(--color-theme-white);
			&_address {
				color: var(--color-theme-black);
				line-height: 1.3;
			}
			&_email {
				font-weight: var(--font-weight-theme-medium);
				font-size: var(--font-size-theme-upper);
				&:hover {
					color: var(--color-theme-gray);
				}
			}
			&_number {
				font-weight: var(--font-weight-theme-medium);
				font-size: var(--font-size-theme-2xl);
				@include mq($until: small-mobile) {
					font-size: var(--font-size-theme-large);
				}
				&:hover {
					color: var(--color-theme-gray);
				}
			}
		}
		&-icon {
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: contain;
			&_mail {
				width: 17px;
				height: 17px;
				mask-image: url(../../../icons/envelope.svg);
				background-color: var(--color-theme-white);
				margin-right: 7px;
			}
			&_location {
				width: 35px;
				height: 35px;
				mask-image: url(../../../icons/placeholder.svg);
				background-color: var(--color-theme-black);
				margin-right: 10px;
			}
		}
	}
}
