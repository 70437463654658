.section-header {
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
	$header: &;
	&_center {
		justify-content: center;
		align-items: center;
		#{$header}__title {
			font-weight: var(--font-weight-theme-medium);
			font-size: var(--font-size-theme-3xl);
			text-align: center;
			@include mq($from: fullhd) {
				max-width: 80%;
			}
		}
		#{$header}__description {
			text-align: center;
			@include mq($from: fullhd) {
				max-width: 70%;
			}
		}
	}
	&_left {
		justify-content: flex-start;
		#{$header}__title {
			font-weight: var(--font-weight-theme-bold);
			text-align: left;
			// @include mq($until: fullhd) {
			// 	max-width: 80%;
			// }
		}
		#{$header}__description {
			text-align: left;
			// @include mq($until: fullhd) {
			// 	max-width: 70%;
			// }
		}
	}
	&_medium {
		#{$header}__title {
			font-size: var(--font-size-theme-5xl);
			@include mq($until: desktop) {
				font-size: var(--font-size-theme-3xl);
			}
			@include mq($until: mobile) {
				font-size: var(--font-size-theme-xl);
			}
		}
		#{$header}__description {
			font-size: var(--font-size-theme-upper);
			@include mq($until: mobile) {
				font-size: var(--font-size-theme-base);
			}
		}
	}
	&_large {
		#{$header}__title {
			font-size: var(--font-size-theme-7xl);
			@include mq($until: desktop) {
				font-size: var(--font-size-theme-5xl);
				max-width: 90%;
			}
			@include mq($until: mobile) {
				font-size: var(--font-size-theme-3xl);
			}
		}
		#{$header}__description {
			font-size: var(--font-size-theme-large);
			@include mq($until: mobile) {
				max-width: 80%;
				font-size: var(--font-size-theme-upper);
			}
		}
	}
	&_white {
		color: var(--color-theme-white);
	}
	&_black {
		color: var(--color-theme-black);
	}

	&__title {
		max-width: 85%;
		line-height: 1.3;
	}

	&__description {
		margin-top: 15px;
		max-width: 75%;
	}
}
