.page-input {
	padding: 10px 0;
	font-size: var(--font-size-theme-upper);
	color: var(--color-theme-white);
	font-weight: var(--font-weight-theme-medium);
	appearance: none;
	border: none;
	border-bottom: 1px solid var(--color-theme-white);
	background-color: transparent;
	outline: none;
	&::placeholder {
		color: var(--color-theme-whitesmoke);
	}
	&__group {
		display: flex;
		flex-direction: column;
	}
	&__label {
		color: var(--color-theme-white);
		font-weight: var(--font-weight-theme-regular);
	}
}
