.cortes-video {
	background-color: var(--color-theme-blue);
	padding-top: 80px;
	@include mq($until: small-mobile) {
		padding-top: 50px;
	}
	&__container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		overflow: hidden;
		background-color: var(--color-theme-whitesmoke);
		box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
		&_loaded {
			background-color: transparent;
		}
	}

	video {
		width: 100%;
		height: auto;
	}

	&__loader {
		@include loader;
		position: absolute;
		width: 70px;
		height: 70px;
	}
}
