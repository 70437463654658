.device-autofocus {
	padding: var(--section-padding);
	background-color: var(--color-theme-whitesmoke);
	@include mq($until: fullhd) {
		display: none;
	}
	&__content {
		display: flex;
	}
	&__shot {
		flex: 1;
		&:first-child {
			margin-right: 100px;
		}

		&-image {
			height: 500px;
			background-color: black;
		}
		&-content {
			margin-top: 40px;
			padding: 0 10px;
		}
		&-info {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			&-icon {
				width: 25px;
				height: 25px;
				background-color: var(--color-theme-black);
				margin-right: 10px;
				mask-position: center;
				mask-repeat: no-repeat;
				mask-size: contain;
				&_sun {
					mask-image: url(../../../icons/sun.svg);
				}
				&_moon {
					mask-image: url(../../../icons/moon.svg);
					mask-size: 90%;
				}
			}
			&-title {
				font-size: var(--font-size-theme-large);
				font-weight: var(--font-weight-theme-medium);
			}
		}
		&-description {
			max-width: 95%;
			padding-left: 35px;
		}
		&-text {
			margin-bottom: 20px;
			font-size: var(--font-size-theme-upper);
			line-height: 1.4;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
