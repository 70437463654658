.aurora-contact {
	padding: var(--section-padding);
	background-color: var(--color-theme-whitesmoke);

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 50px;
	}
	&__promo {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
	}
	&__title {
		font-size: var(--font-size-theme-7xl);
		font-weight: var(--font-weight-theme-medium);
		text-align: center;
		margin-bottom: 10px;
		@include mq($until: desktop) {
			line-height: 1.3;
		}
		@include mq($until: mobile) {
			font-size: var(--font-size-theme-3xl);
		}
	}

	&__description {
		font-size: var(--font-size-theme-upper);
		width: 70%;
		text-align: center;
		line-height: 1.4;
		@include mq($until: desktop) {
			width: 80%;
		}
		@include mq($until: tablet) {
			width: auto;
		}
	}
	&__button {
		font-weight: var(--font-weight-theme-regular);
		padding: 10px 25px;
		box-shadow: none;
	}
	&__pattern {
		height: 300px;
		background-image: url(../../../extra/aurora-contact.svg);
		margin: 0 -1.5rem calc(-3rem - 30px) -1.5rem;
		@include mq($until: fullhd) {
			height: 250px;
		}
		@include mq($until: tablet) {
			height: 220px;
		}
		@include mq($until: small-mobile) {
			height: 180px;
		}
	}
}
