.home-container {
	height: calc(80vh - 60px);
	background-color: var(--color-theme-gray);
	@include mq($from: 1500px, $media-feature: height) {
		height: calc(50vh - 60px);
	}
	@include mq($from: 700px, $until: 1200px, $media-feature: height) {
		height: calc(90vh - 60px);
	}
	@include mq($from: 600px, $until: 700px, $media-feature: height) {
		height: calc(105vh - 60px);
	}
	@include mq($until: 600px, $media-feature: height) {
		height: calc(140vh - 60px);
	}
	&__container {
		height: 100%;
	}
	&__promo {
		display: flex;
		flex-direction: column;
		width: min-content;
		padding-top: 80px;
		@include mq($until: small-mobile) {
			padding-top: 20px;
		}
		@include mq($until: 800px, $media-feature: height, $and: mq($until: fullhd)) {
			padding-top: 40px;
		}
	}
	&__slogan {
		width: max-content;
		&-phrase {
			// padding: 0 10px;
			font-size: 42px;
			width: max-content;
			font-weight: var(--font-weight-theme-medium);
			// background-color: var(--color-theme-black);
			color: var(--color-theme-black);
			line-height: 1.3;
			@include mq($until: tablet) {
				font-size: var(--font-size-theme-xl);
			}
		}
	}
	&__description {
		margin: 20px 0 40px 0;
	}
	&__contact {
		display: flex;
		align-items: center;
	}
	&__feedback {
		display: flex;
		align-items: center;
		margin-right: 50px;
		padding: 7px 20px;
        border-radius: 100px;
        cursor: pointer;
		background-color: var(--color-theme-blue);
		&-text {
			color: var(--color-theme-white);
			margin-right: 10px;
		}
		&-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 15px;
			height: 15px;
			&-content {
				mask-image: url(../../../icons/right-arrow.svg);
				mask-position: center;
				mask-repeat: no-repeat;
				width: 100%;
				height: 100%;
				background-color: var(--color-theme-white);
			}
		}
	}
	&__find-more {
		color: var(--color-theme-black);
		cursor: pointer;
		@include mq($until: mobile) {
			display: none;
		}
	}
}
