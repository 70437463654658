.device-specifications {
	padding: var(--section-padding);
	background-color: var(--color-theme-whitesmoke);

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 100px 50px 50px 50px;
		border-radius: 5px;
		background-color: var(--color-theme-gray);
		@include mq($until: desktop) {
			padding: 40px 20px;
		}
	}

	&__general {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-gap: 100px 50px;
		margin-bottom: 70px;
		@include mq($until: desktop) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(3, 1fr);
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		line-height: 1;
		&-specification {
			font-size: var(--font-size-theme-8xl);
			font-weight: var(--font-weight-theme-medium);
			color: var(--color-theme-black);
		}
		&-title {
			font-size: var(--font-size-theme-medium);
			font-weight: var(--font-weight-theme-medium);
			color: var(--color-theme-black);
			margin: 10px 0;
			@include mq($until: mobile) {
				font-size: var(--font-size-theme-upper);
			}
		}
		&-subtitle {
			font-size: var(--font-size-theme-upper);
			@include mq($until: mobile) {
				font-size: var(--font-size-theme-base);
			}
		}
	}
	&__more {
		display: inline-flex;
		padding: 10px 20px;
		background-color: black;
		border-radius: 100px;
		&_active {
			padding: 20px 30px;
			width: 100%;
			border-radius: 10px;
		}
		&-button {
			display: flex;
			align-items: center;
			justify-content: center;
			&_hidden {
				display: none;
			}
			&-icon {
				width: 20px;
				height: 20px;
				background-color: #fff;
				margin-right: 10px;
				mask-image: url(../../../icons/information.svg);
				mask-repeat: no-repeat;
				mask-position: center;
			}
			&-text {
				color: var(--color-theme-white);
				line-height: 1;
			}
		}
		&-text {
			width: 0;
			height: 0;
			overflow: hidden;
			&_active {
				width: 100%;
				height: auto;
				overflow: visible;
			}
		}
	}
}
