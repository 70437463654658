.item-documents {
	background-color: var(--color-theme-white);
	border-radius: 10px;
	height: 100%;
	flex: 1;
	&__content {
		padding: 40px 35px;
        display: flex;
        

        @include mq($until: mobile) {
            flex-direction: column;
        }
	}
	&__primary {
		margin-right: 30px;
        flex: 1;
        @include mq($until: mobile) {
            margin: 0 0 30px 0;
        }
	}
}
