.company-solutions {
	background-color: var(--color-theme-blue);
	&__title {
		text-align: center;
		//
		color: var(--color-theme-white);
		font-weight: var(--font-weight-theme-medium);
		font-size: var(--font-size-theme-3xl);
		margin-bottom: 30px;
	}
	&__products {
		justify-content: center;

		//
		display: grid;
		grid-gap: 40px;
		grid-template-columns: repeat(auto-fit, minmax(200px, 330px));
		@include mq($from: desktop, $until: fullhd) {
			grid-template-columns: repeat(auto-fit, minmax(150px, 300px));
			justify-content: space-between;
			grid-gap: unset;
        }
        //remove
		> div {
			.company-solution__button {
				opacity: 0;
			}
			&:nth-child(3) {
				.company-solution__button {
					opacity: 1;
				}
			}
		}
	}
}
