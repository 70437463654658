.cortes-head {
	// height: calc(80vh - 60px);
	// background-color: var(--color-theme-dark-blue);

	&__background {
		position: absolute;
		background-color: var(--color-theme-dark-blue);
		height: 1000px;
		right: 0;
		left: 0;
		@include mq($from: 1500, $media-feature: height, $and: mq($from: fullhd)) {
			height: 1200px;
		}
		@include mq($until: 1500, $media-feature: height, $and: mq($from: fullhd)) {
			height: 1000px;
		}
		@include mq($until: 1200, $media-feature: height, $and: mq($until: fullhd)) {
			height: 1000px;
		}
		@include mq($until: 1000, $media-feature: height, $and: mq($until: fullhd)) {
			height: 900px;
		}
		@include mq($until: 1200, $media-feature: height, $and: mq($until: desktop)) {
			height: 900px;
		}
		@include mq($until: 1000, $media-feature: height, $and: mq($until: desktop)) {
			height: 750px;
		}
		@include mq($until: 1200, $media-feature: height, $and: mq($until: mobile)) {
			height: 750px;
		}
		@include mq($until: 900, $media-feature: height, $and: mq($until: mobile)) {
			height: 550px;
        }
        
	}
	section {
		padding: 0 1.5rem;
	}
	&__content {
		flex-direction: column;
		display: flex;
		align-items: center;
		padding-top: 10vh;
		&-text {
            width: min-content;
            display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	&__title {
		width: max-content;
		color: var(--color-theme-white);
		font-size: var(--font-size-theme-big-slogan);
		font-weight: var(--font-weight-theme-medium);
		text-align: center;
		line-height: 1.3;
		margin-bottom: 10px;
		@include mq($until: widescreen) {
			font-size: var(--font-size-theme-slogan);
		}
		@include mq($until: tablet) {
			font-size: var(--font-size-theme-8xl);
		}
		@include mq($until: mobile) {
			font-size: var(--font-size-theme-5xl);
		}
		@include mq($until: small-mobile) {
			font-size: var(--font-size-theme-xl);
		}
	}
	&__description {
		color: var(--color-theme-white);
		font-size: var(--font-size-theme-xl);
		line-height: 1.4;
		text-align: center;
		max-width: 90%;
		margin-bottom: 50px;
		@include mq($until: widescreen) {
			font-size: var(--font-size-theme-large);
		}
		@include mq($until: mobile) {
			font-size: var(--font-size-theme-upper);
			// max-width: 80vw;
		}
		@include mq($until: small-mobile) {
			font-size: var(--font-size-theme-upper);
			// max-width: 90vw;
		}
	}
	&__actions {
		display: flex;
		align-items: center;
	}
	&__download {
		display: flex;
		align-items: center;
		padding: 10px 20px;
		border-radius: 5px;
		margin-right: 40px;
		background-color: var(--color-theme-white);
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		cursor: pointer;
        transition: all 0.1s ease-in;
        @include mq($until: small-mobile) {
			display: none;
		}
		&-text {
			color: var(--color-theme-black);
		}
		&:hover {
			transform: translateY(-1px) scale(1.005);
		}
	}
	&__more {
		display: flex;
		align-items: center;
		font-weight: var(--font-weight-theme-regular);
		cursor: pointer;
		$more: &;
		&:hover {
			#{$more}-icon {
				transform: translateX(2px) scale(1.005);
			}
		}
		&-text {
			font-size: var(--font-size-theme-upper);
			color: var(--color-theme-white);
			margin-right: 7px;
			line-height: 1;
		}
		&-icon {
			width: 17px;
			height: 17px;
			background-color: var(--color-theme-white);
			mask-image: url(../../../icons/bold-right-arrow.svg);
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: contain;
			transition: all 0.1s ease-in;
		}
	}
}
