.page-button {
	display: flex;
	align-items: center;
	padding: 10px 20px;
	border-radius: 5px;
	background-color: var(--color-theme-white);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	transition: all 0.1s ease-in;
}
